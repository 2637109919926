import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import { graphql } from "gatsby";
import { Container, Box, Typography } from "@mui/material";
import isoeng from "../images/iso/Certificate ISO 9001_2015.pdf";
import isoger from "../images/iso/Zertifikat ISO 9001_2015.pdf";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import download from "../images/download_quality.svg";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#202026",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const Press = ({ data, location }) => {
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  if (isBrowser) {
    window.addEventListener("scroll", reveal);
  }

  const siteTitle = data.site.siteMetadata.title;

  return (
    <ThemeProvider theme={cs_theme}>
      <Layout location={location} title={siteTitle}>
        <Seo title="Quality Management | Custom Surgical" />
        <Box className="quality_management">
          <Container maxWidth="lg">
            <Typography
              variant="h3"
              color={"white"}
              fontWeight="bold"
              padding={{ xs: 4, sm: 8, md: 12 }}
              style={{
                display: "flex",
                height: "60vh",
                alignItems: "flex-end",
                textShadow: "#000 1px 0 10px",
              }}
            >
              QUALITY MANAGEMENT
            </Typography>
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Typography variant="h6" paddingTop={{ xs: 3, sm: 5, md: 7 }}>
            Custom Surgical GmbH is a medical devices manufacturer registered in
            EUDAMED and the FDA.Custom Surgical GmbH has put in place a
            certified quality management system in accordance with the standards
            DIN EN ISO 9001:2015. In addition, the system meets the requirements
            of the European Medical Devices Regulation and Good Manufacturing
            Practices (GMP).
          </Typography>
          <Typography variant="h6" paddingTop={{ xs: 3, sm: 3, md: 3 }}>
            Custom Surgical GmbH places a strong emphasis on ensuring the safety
            of their products. To ensure product quality, they use only
            components that have demonstrated reliability and safety. They work
            closely with accredited inspection offices to conduct detailed
            testing and obtain certifications for their products. The
            manufacturing and testing processes for their products are
            dependable, efficient, and continuously monitored.
          </Typography>
          <Typography variant="h6" paddingTop={{ xs: 3, sm: 3, md: 3 }}>
            Furthermore, each production process is followed by safety testing
            using specialized testing systems, and the outcomes are meticulously
            recorded. As a result, each product is uniquely identified and
            certified before it is delivered
          </Typography>{" "}
          <h2>Information</h2>
          <a
            href="https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfRL/rl.cfm?rid=316653"
            target="_blank"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "2em",
              }}
            >
              <img src={download} style={{ maxWidth: "100%" }}></img>
              <h5 style={{ margin: "0", paddingLeft: "1em" }}>
                FDA Establishment Registration & Device Listing - Custom
                Surgical GMBH
              </h5>
            </div>
          </a>
          <a
            href="https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfRL/rl.cfm?lid=837083&lpcd=HRB"
            target="_blank"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "2em",
              }}
            >
              <img src={download} style={{ maxWidth: "100%" }}></img>
              <h5 style={{ margin: "0", paddingLeft: "1em" }}>
                FDA Establishment Registration & Device Listing - MicroREC
              </h5>
            </div>
          </a>
          <a href={isoeng} target="_blank" download>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "2em",
              }}
            >
              <img src={download} style={{ maxWidth: "100%" }}></img>
              <h5 style={{ margin: "0", paddingLeft: "1em" }}>
                English - ISO 9001:2015 Quality Management System
              </h5>
            </div>
          </a>
          <a href={isoger} target="_blank" download>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "2em",
              }}
            >
              <img src={download} style={{ maxWidth: "100%" }}></img>
              <h5 style={{ margin: "0", paddingLeft: "1em" }}>
                German - ISO 9001:2015 Quality Management System
              </h5>
            </div>
          </a>
          <a
            href="https://eservice.hsa.gov.sg/medics/md/mdEnquiry.do?action=loadClassA"
            target="_blank"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "2em",
                marginBottom: "4em",
              }}
            >
              <img src={download} style={{ maxWidth: "100%" }}></img>
              <h5 style={{ margin: "0", paddingLeft: "1em" }}>
                MicroREC - Medical Device Class A according to Health Science
                Authority from Singapore
              </h5>
            </div>
          </a>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default Press;

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allInfoJson {
      nodes {
        id
        date(formatString: "")
        zip {
          publicURL
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        title
        pdf {
          publicURL
        }
      }
    }
    allPicturesJson {
      nodes {
        zip {
          publicURL
        }
        img {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allLogoJson {
      nodes {
        zip {
          publicURL
        }
        img {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allNewsJson {
      nodes {
        pdf {
          publicURL
        }
        img1 {
          childImageSharp {
            gatsbyImageData
          }
        }
        img2 {
          childImageSharp {
            gatsbyImageData
          }
        }
        link
        text
      }
    }
  }
`;
